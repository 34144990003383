<template>
  <div class="GoalsOuter">
    <div class="contentleft-header">
      <!-- <div><b-button  type="is-info" icon-right="plus" @click="shortlistAdd">Shortlist</b-button></div> -->
      <div class="ml11"><b-button  type="is-info" icon-right="comments-alt" @click="sendMessage">
        <span v-if="!incircles">Introduce yourself</span>
        <span v-if="incircles">Send Message</span>
      </b-button></div>
    </div>
    <div class="GoalInner">
      <div class="GoalContentBar">
        <b-loading :active.sync="isLoading" :can-cancel="false" is-full-page></b-loading>
        <div class="GoalColumn" style="width: 380px; position: relative">
          <div class="isFlexGrow scrolly p11">
            <!-- image: start -->
              <div class="isFlex isFlexJustify">
                <div class="isPointer" @click="changeImage()">
                  <img
                    src="/dummy/120x120.png"
                    class="thumb thumb-xlg thumb-circle"
                    v-if="User.photo === ''"
                  />
                  <img
                    :src="User.photo"
                    class="thumb thumb-xlg thumb-circle"
                    v-else
                  />
                </div>
              </div>
              <!-- image: end  -->
              <h3 class="txth2 txtc mb5">
                {{ User.firstname }} {{ User.lastname }}
              </h3>
              <div class="txtc blueborder10bottom pb11 mb11">
                {{ User.oneliner }}
              </div>
              
              <div class="ProfileDesc p3" v-html="User.description"></div>
          </div>
        </div>

        <!-- availability: start -->
        <div class="GoalColumn">
          <div class="txtc p7 lgreyborderbottom whitebg">Availability</div>
          <div class="isFlexGrow scrolly">
            <div class="tsk p3 vlgreybg midgrey m3" v-if="Avail.length === 0">No availability slots found.</div>

            <div v-for="(av, index) in Avail" :key="index">
              <div class="txtc p7 lgreyborderbottom lgreyborderbottom whitebg txtbold">{{av.daytxt | displaydateFull}}</div>
              <div v-for="(ev, index) in av.slots" :key="index" class="lgreyborderbottom p3 vlgreybg p11">
                
                <div class="isFlex">
                  <div class="txtbold">{{ ev.hourstart }}:{{ ev.minutestart }} {{ ev.slottype }}</div>
                  
                </div>
                <div class="isFlex mv3">
                  <div class="mr5">Duration</div>
                  <div>
                    <span><span class="txtbold">{{ ev.hours }} </span> hrs </span>
                    <span><span class="txtbold">{{ ev.minutes }} </span> mins </span>
                  </div>
                </div>
                <div class="mv5">{{ev.title}}</div>
                <div>Session size: {{ev.attendees}}</div>
                <div>Available Slots: {{ev.attendees - ev.booked}}</div>
                <div class="mt11"><b-button size="is-small" type="is-info" @click="makeBooking(av, ev)" expanded>Book Session</b-button></div>
              </div>
            </div>

          </div>
        </div>
        <!-- availability: end -->
        <!-- services: start -->
        <div class="GoalColumn">
          <div class="txtc p7 lgreyborderbottom whitebg">Services Offered</div>
          <div class="isFlexGrow scrolly p11">
            <div>
              <div class="tsk p3 vlgreybg midgrey m3" v-if="User.services && User.services.length === 0">No services defined</div>
              <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in User.services" :key="ti">
                <div class="isFlexGrow">{{t.text}}</div>
              </div>
            </div>
          </div>
          <div class="txtc p7 lgreyborderbottom whitebg">Help needed</div>
          <div class="isFlexGrow scrolly p11">
            <div>
              <div class="tsk p3 vlgreybg midgrey m3" v-if="User.helpneeded && User.helpneeded.length === 0">No helpneeded defined</div>
              <div class="tsk isFlex p3 vlgreybg m3" v-for="(t, ti) in User.helpneeded" :key="ti">
                <div class="isFlexGrow">{{t.text}}</div>
              </div>
            </div>
          </div>
        </div>
        <!-- services: end -->
        <!-- projects: start -->
        <div class="GoalColumn">
          <div class="txtc p7 lgreyborderbottom whitebg">Projects</div>
          <div class="isFlexGrow scrolly p11">
            <div class="tsk p3 vlgreybg midgrey m3" v-if="User.projects && User.projects.length === 0">No projects listed.</div>
            <div class="tsk p3 vlgreybg m3" v-for="(t, ti) in User.projects" :key="ti">
              <div class="isFlex">
                <div class="isFlexGrow txtbold">{{t.title}}</div>
                <!-- <div class="isFlex" style="min-width: 50px;">
                  <div class="iconbutton" @click="editProject(t, ti)"><font-awesome-icon :icon="['fal', 'edit']" size="sm"></font-awesome-icon></div>
                  <div class="iconbutton handle bluetxt11"><font-awesome-icon :icon="['fal', 'sort']" size="sm"></font-awesome-icon></div>
                </div> -->
              </div>
              <div class="txtsm">{{t.description | shorten(100) }} <span v-if="t.description.length > 200">...more</span></div>
              <div class="txtsm mt5"><span class="txtbold">Role: </span>{{t.role }}</div>
              <div class="txtsm"><span class="txtbold">Status: </span>{{t.status }}</div>
              <div class="buttons mt7">
                <a class="button is-small" :href="t.url" target="_blank" v-if="t.url != ''">Website</a>
                <a class="button is-small" :href="t.deck" target="_blank" v-if="t.deck != ''">Deck/Presentation</a>
              </div>
            </div>
          </div>
        </div>
        <!-- projects: end -->

      </div>
    </div>
    <!-- book module: start -->
    <b-modal :active.sync="showBook" :width="380" scroll="keep">
      <div class="card modal-card roundedtop roundedbottom">
        <div class="card-header roundedtop bluebg11">
          <div class="card-header-title white">Book slot</div>
        </div>
        <div class="modal-card-body">
          <div class="content">
            <template v-if="!loggedin">
              <div>
                <div class="tsk lgreybg" v-if="!showLogin">
                  <div class="mb11">Loggin if you want to do this much faster. If you don't have an account, create one now for FREE! Just enter a password at the bottom of this screen and we will do the work for you.</div>
                  <div><b-button size="is-small" type="is-info" @click="showLogin = true" expanded>I already have an account. Let me login.</b-button></div>
                  <div class="mt11"><b-button size="is-small" type="is-link" @click="gotosignup" expanded>Register for a free account</b-button></div>
                </div>
                <div style="height: 20px;" v-if="!showLogin"></div>
                <template v-if="showLogin">
                  <template>
                    <b-field label="Email" style="color: white;">
                      <b-input v-model="email" type="email" expanded></b-input>
                    </b-field>
                    <b-field label="Password" v-if="showPass">
                      <b-input type="password" v-model="password" password-reveal></b-input>
                    </b-field>
                    <div v-if="showProceed" @click="loginpre">
                      <b-button expanded type="is-link">Proceed</b-button>
                    </div>
                    <div v-if="showPass" @click="loginpass">
                      <b-button expanded type="is-link">Login</b-button>
                    </div>
                    <div class="mt11" v-if="showProceed" @click="showLogin = false">
                      <b-button expanded type="is-light">Cancel</b-button>
                    </div>
                  </template>

                </template>
                <template v-if="!showLogin">
                  <b-field label="First Name">
                    <b-input v-model="firstname"></b-input>
                  </b-field>
                  <b-field label="Last Name">
                    <b-input v-model="lastname"></b-input>
                  </b-field>
                  <b-field label="Email (Required)">
                    <b-input v-model="email"></b-input>
                  </b-field>
                  <b-field label="Contact No.">
                    <b-input v-model="mobile" expanded></b-input>
                  </b-field>
                  <div class="field">
                    <b-checkbox v-model="showRegister">Let me register an account.</b-checkbox>
                  </div>
                  <template v-if="showRegister">
                    <b-field label="Country (Required)">
                      <b-select placeholder="Select a country" v-model="country" expanded>
                        <option
                          v-for="(option, index) in countries"
                          :value="option.countryCode"
                          :key="index">
                          {{ option.label }}
                        </option>
                      </b-select>
                    </b-field>
                    <b-field label="Business Name">
                      <b-input v-model="coname" expanded></b-input>
                    </b-field>
                    <b-field label="Password (Required)">
                      <b-input type="password" v-model="password" password-reveal>
                      </b-input>
                    </b-field>
                  </template>
                </template>
              </div>
            </template>
          </div>
        </div>
        <footer class="card-footer roundedbottom">
          <div class="card-footer-item roundedbottomleft isPointer bluebg1 bluetxt8" @click="showBook=false">Cancel</div>
          <div class="card-footer-item roundedbottomright isPointer bluebg11 white txtbold" @click="makeBookingDo()">Book session</div>
        </footer>
      </div>
    </b-modal>
    <!-- book module: end -->

    <!-- intro module: start -->
    <b-modal :active.sync="isModalIntro" :width="640" scroll="keep">
      <div class="card modal-card roundedtop roundedbottom">
        <div class="card-header roundedtop bluebg10">
          <div class="card-header-title white">
            <span v-if="!incircles">Introduce yourself</span>
            <span v-if="incircles">Send Message</span>
          </div>
        </div>
        <div class="modal-card-body">
          <div class="content">
              <b-field label="Add a message">
                <b-input v-model="intromessage" type="textarea" minlength="100" maxlength="500"></b-input>
              </b-field>
          </div>
        </div>
        <footer class="card-footer roundedbottom">
          <div class="card-footer-item roundedbottomleft isPointer bluebg1 bluetxt8" @click="isModalIntro=false">Cancel</div>
          <div class="card-footer-item roundedbottomright isPointer bluebg10 white txtbold" @click="sendIntro">Send</div>
        </footer>
      </div>
    </b-modal>
    <!-- intro module: end -->
  </div>
</template>

<script>
// @ is an alias to /src
import { EventBus } from '../event-bus.js';
import { Utils } from '../mixins/utils.js';

export default {
  mixins: [Utils],
  name: 'blank',
  data() {
    return {
      isLoading: true,
      id: this.$route.params.id,
      area: this.$route.params.area,
      User: {},
      Avail: [],
      loggedin: false,
      incircles: false,
      showBook: false,
      showLogin: false,
      showRegister: false,
      ActiveDay: {},
      ActiveBooking: {},
      loggedin: false,
      sub: '',
      subname: '',
      handle: '',
      profile: '',
      firstname: "",
      lastname: "",
      coname: "",
      mobile: "",
      email: "",
      password: "",
      country: "GB",
      showProceed: true,
      showPass: false,
      countries: [
        { countryCode: 'GB', value: '44', label: 'UK (+44)' },
        { countryCode: 'US', value: '1', label: 'USA (+1)' },
        { countryCode: 'IN', value: '91', label: 'India (+91)' },
        { countryCode: 'ZA', value: '27', label: 'South Africa (+27)' },
        { countryCode: 'AU', value: '61', label: 'Australia (+61)' },
        { countryCode: 'CA', value: '1', label: 'Canada (+1)' },
        { countryCode: '', value: '', label: '---' },
        { countryCode: 'DZ', value: '213', label: 'Algeria (+213)' },
        { countryCode: 'AD', value: '376', label: 'Andorra (+376)' },
        { countryCode: 'AO', value: '244', label: 'Angola (+244)' },
        { countryCode: 'AI', value: '1264', label: 'Anguilla (+1264)' },
        { countryCode: 'AG', value: '1268', label: 'Antigua & Barbuda (+1268)' },
        { countryCode: 'AR', value: '54', label: 'Argentina (+54)' },
        { countryCode: 'AM', value: '374', label: 'Armenia (+374)' },
        { countryCode: 'AW', value: '297', label: 'Aruba (+297)' },
        { countryCode: 'AU', value: '61', label: 'Australia (+61)' },
        { countryCode: 'AT', value: '43', label: 'Austria (+43)' },
        { countryCode: 'AZ', value: '994', label: 'Azerbaijan (+994)' },
        { countryCode: 'BS', value: '1242', label: 'Bahamas (+1242)' },
        { countryCode: 'BH', value: '973', label: 'Bahrain (+973)' },
        { countryCode: 'BD', value: '880', label: 'Bangladesh (+880)' },
        { countryCode: 'BB', value: '1246', label: 'Barbados (+1246)' },
        { countryCode: 'BY', value: '375', label: 'Belarus (+375)' },
        { countryCode: 'BE', value: '32', label: 'Belgium (+32)' },
        { countryCode: 'BZ', value: '501', label: 'Belize (+501)' },
        { countryCode: 'BJ', value: '229', label: 'Benin (+229)' },
        { countryCode: 'BM', value: '1441', label: 'Bermuda (+1441)' },
        { countryCode: 'BT', value: '975', label: 'Bhutan (+975)' },
        { countryCode: 'BO', value: '591', label: 'Bolivia (+591)' },
        { countryCode: 'BA', value: '387', label: 'Bosnia Herzegovina (+387)' },
        { countryCode: 'BW', value: '267', label: 'Botswana (+267)' },
        { countryCode: 'BR', value: '55', label: 'Brazil (+55)' },
        { countryCode: 'BN', value: '673', label: 'Brunei (+673)' },
        { countryCode: 'BG', value: '359', label: 'Bulgaria (+359)' },
        { countryCode: 'BF', value: '226', label: 'Burkina Faso (+226)' },
        { countryCode: 'BI', value: '257', label: 'Burundi (+257)' },
        { countryCode: 'KH', value: '855', label: 'Cambodia (+855)' },
        { countryCode: 'CM', value: '237', label: 'Cameroon (+237)' },
        { countryCode: 'CA', value: '1', label: 'Canada (+1)' },
        { countryCode: 'CV', value: '238', label: 'Cape Verde Islands (+238)' },
        { countryCode: 'KY', value: '1345', label: 'Cayman Islands (+1345)' },
        { countryCode: 'CF', value: '236', label: 'Central African Republic (+236)' },
        { countryCode: 'CL', value: '56', label: 'Chile (+56)' },
        { countryCode: 'CN', value: '86', label: 'China (+86)' },
        { countryCode: 'CO', value: '57', label: 'Colombia (+57)' },
        { countryCode: 'KM', value: '269', label: 'Comoros (+269)' },
        { countryCode: 'CG', value: '242', label: 'Congo (+242)' },
        { countryCode: 'CK', value: '682', label: 'Cook Islands (+682)' },
        { countryCode: 'CR', value: '506', label: 'Costa Rica (+506)' },
        { countryCode: 'HR', value: '385', label: 'Croatia (+385)' },
        { countryCode: 'CU', value: '53', label: 'Cuba (+53)' },
        { countryCode: 'CW', value: '599', label: 'Curacao (+599)' },
        { countryCode: 'CY', value: '90392', label: 'Cyprus North (+90392)' },
        { countryCode: 'CY', value: '357', label: 'Cyprus South (+357)' },
        { countryCode: 'CZ', value: '42', label: 'Czech Republic (+42)' },
        { countryCode: 'DK', value: '45', label: 'Denmark (+45)' },
        { countryCode: 'DJ', value: '253', label: 'Djibouti (+253)' },
        { countryCode: 'DM', value: '1809', label: 'Dominica (+1809)' },
        { countryCode: 'DO', value: '1809', label: 'Dominican Republic (+1809)' },
        { countryCode: 'EC', value: '593', label: 'Ecuador (+593)' },
        { countryCode: 'EG', value: '20', label: 'Egypt (+20)' },
        { countryCode: 'SV', value: '503', label: 'El Salvador (+503)' },
        { countryCode: 'GQ', value: '240', label: 'Equatorial Guinea (+240)' },
        { countryCode: 'ER', value: '291', label: 'Eritrea (+291)' },
        { countryCode: 'EE', value: '372', label: 'Estonia (+372)' },
        { countryCode: 'ET', value: '251', label: 'Ethiopia (+251)' },
        { countryCode: 'FK', value: '500', label: 'Falkland Islands (+500)' },
        { countryCode: 'FO', value: '298', label: 'Faroe Islands (+298)' },
        { countryCode: 'FJ', value: '679', label: 'Fiji (+679)' },
        { countryCode: 'FI', value: '358', label: 'Finland (+358)' },
        { countryCode: 'FR', value: '33', label: 'France (+33)' },
        { countryCode: 'GF', value: '594', label: 'French Guiana (+594)' },
        { countryCode: 'PF', value: '689', label: 'French Polynesia (+689)' },
        { countryCode: 'GA', value: '241', label: 'Gabon (+241)' },
        { countryCode: 'GM', value: '220', label: 'Gambia (+220)' },
        { countryCode: 'GE', value: '7880', label: 'Georgia (+7880)' },
        { countryCode: 'DE', value: '49', label: 'Germany (+49)' },
        { countryCode: 'GH', value: '233', label: 'Ghana (+233)' },
        { countryCode: 'GI', value: '350', label: 'Gibraltar (+350)' },
        { countryCode: 'GR', value: '30', label: 'Greece (+30)' },
        { countryCode: 'GL', value: '299', label: 'Greenland (+299)' },
        { countryCode: 'GD', value: '1473', label: 'Grenada (+1473)' },
        { countryCode: 'GP', value: '590', label: 'Guadeloupe (+590)' },
        { countryCode: 'GU', value: '671', label: 'Guam (+671)' },
        { countryCode: 'GT', value: '502', label: 'Guatemala (+502)' },
        { countryCode: 'GN', value: '224', label: 'Guinea (+224)' },
        { countryCode: 'GW', value: '245', label: 'Guinea - Bissau (+245)' },
        { countryCode: 'GY', value: '592', label: 'Guyana (+592)' },
        { countryCode: 'HT', value: '509', label: 'Haiti (+509)' },
        { countryCode: 'HN', value: '504', label: 'Honduras (+504)' },
        { countryCode: 'HK', value: '852', label: 'Hong Kong (+852)' },
        { countryCode: 'HU', value: '36', label: 'Hungary (+36)' },
        { countryCode: 'IS', value: '354', label: 'Iceland (+354)' },
        { countryCode: 'IN', value: '91', label: 'India (+91)' },
        { countryCode: 'ID', value: '62', label: 'Indonesia (+62)' },
        { countryCode: 'IR', value: '98', label: 'Iran (+98)' },
        { countryCode: 'IQ', value: '964', label: 'Iraq (+964)' },
        { countryCode: 'IE', value: '353', label: 'Ireland (+353)' },
        { countryCode: 'IL', value: '972', label: 'Israel (+972)' },
        { countryCode: 'IT', value: '39', label: 'Italy (+39)' },
        { countryCode: 'JM', value: '1876', label: 'Jamaica (+1876)' },
        { countryCode: 'JP', value: '81', label: 'Japan (+81)' },
        { countryCode: 'JO', value: '962', label: 'Jordan (+962)' },
        { countryCode: 'KZ', value: '7', label: 'Kazakhstan (+7)' },
        { countryCode: 'KE', value: '254', label: 'Kenya (+254)' },
        { countryCode: 'KI', value: '686', label: 'Kiribati (+686)' },
        { countryCode: 'KP', value: '850', label: 'Korea North (+850)' },
        { countryCode: 'KR', value: '82', label: 'Korea South (+82)' },
        { countryCode: 'KW', value: '965', label: 'Kuwait (+965)' },
        { countryCode: 'KG', value: '996', label: 'Kyrgyzstan (+996)' },
        { countryCode: 'LA', value: '856', label: 'Laos (+856)' },
        { countryCode: 'LV', value: '371', label: 'Latvia (+371)' },
        { countryCode: 'LB', value: '961', label: 'Lebanon (+961)' },
        { countryCode: 'LS', value: '266', label: 'Lesotho (+266)' },
        { countryCode: 'LR', value: '231', label: 'Liberia (+231)' },
        { countryCode: 'LY', value: '218', label: 'Libya (+218)' },
        { countryCode: 'LI', value: '417', label: 'Liechtenstein (+417)' },
        { countryCode: 'LT', value: '370', label: 'Lithuania (+370)' },
        { countryCode: 'LU', value: '352', label: 'Luxembourg (+352)' },
        { countryCode: 'MO', value: '853', label: 'Macao (+853)' },
        { countryCode: 'MK', value: '389', label: 'Macedonia (+389)' },
        { countryCode: 'MG', value: '261', label: 'Madagascar (+261)' },
        { countryCode: 'MW', value: '265', label: 'Malawi (+265)' },
        { countryCode: 'MY', value: '60', label: 'Malaysia (+60)' },
        { countryCode: 'MV', value: '960', label: 'Maldives (+960)' },
        { countryCode: 'ML', value: '223', label: 'Mali (+223)' },
        { countryCode: 'MT', value: '356', label: 'Malta (+356)' },
        { countryCode: 'MH', value: '692', label: 'Marshall Islands (+692)' },
        { countryCode: 'MQ', value: '596', label: 'Martinique (+596)' },
        { countryCode: 'MR', value: '222', label: 'Mauritania (+222)' },
        { countryCode: 'YT', value: '269', label: 'Mayotte (+269)' },
        { countryCode: 'MX', value: '52', label: 'Mexico (+52)' },
        { countryCode: 'FM', value: '691', label: 'Micronesia (+691)' },
        { countryCode: 'MD', value: '373', label: 'Moldova (+373)' },
        { countryCode: 'MC', value: '377', label: 'Monaco (+377)' },
        { countryCode: 'MN', value: '976', label: 'Mongolia (+976)' },
        { countryCode: 'MS', value: '1664', label: 'Montserrat (+1664)' },
        { countryCode: 'MA', value: '212', label: 'Morocco (+212)' },
        { countryCode: 'MZ', value: '258', label: 'Mozambique (+258)' },
        { countryCode: 'MN', value: '95', label: 'Myanmar (+95)' },
        { countryCode: 'NA', value: '264', label: 'Namibia (+264)' },
        { countryCode: 'NR', value: '674', label: 'Nauru (+674)' },
        { countryCode: 'NP', value: '977', label: 'Nepal (+977)' },
        { countryCode: 'NL', value: '31', label: 'Netherlands (+31)' },
        { countryCode: 'NC', value: '687', label: 'New Caledonia (+687)' },
        { countryCode: 'NZ', value: '64', label: 'New Zealand (+64)' },
        { countryCode: 'NI', value: '505', label: 'Nicaragua (+505)' },
        { countryCode: 'NE', value: '227', label: 'Niger (+227)' },
        { countryCode: 'NG', value: '234', label: 'Nigeria (+234)' },
        { countryCode: 'NU', value: '683', label: 'Niue (+683)' },
        { countryCode: 'NF', value: '672', label: 'Norfolk Islands (+672)' },
        { countryCode: 'NP', value: '670', label: 'Northern Marianas (+670)' },
        { countryCode: 'NO', value: '47', label: 'Norway (+47)' },
        { countryCode: 'OM', value: '968', label: 'Oman (+968)' },
        { countryCode: 'PW', value: '680', label: 'Palau (+680)' },
        { countryCode: 'PA', value: '507', label: 'Panama (+507)' },
        { countryCode: 'PG', value: '675', label: 'Papua New Guinea (+675)' },
        { countryCode: 'PY', value: '595', label: 'Paraguay (+595)' },
        { countryCode: 'PE', value: '51', label: 'Peru (+51)' },
        { countryCode: 'PH', value: '63', label: 'Philippines (+63)' },
        { countryCode: 'PL', value: '48', label: 'Poland (+48)' },
        { countryCode: 'PT', value: '351', label: 'Portugal (+351)' },
        { countryCode: 'PR', value: '1787', label: 'Puerto Rico (+1787)' },
        { countryCode: 'QA', value: '974', label: 'Qatar (+974)' },
        { countryCode: 'RE', value: '262', label: 'Reunion (+262)' },
        { countryCode: 'RO', value: '40', label: 'Romania (+40)' },
        { countryCode: 'RU', value: '7', label: 'Russia (+7)' },
        { countryCode: 'RW', value: '250', label: 'Rwanda (+250)' },
        { countryCode: 'SM', value: '378', label: 'San Marino (+378)' },
        { countryCode: 'ST', value: '239', label: 'Sao Tome & Principe (+239)' },
        { countryCode: 'SA', value: '966', label: 'Saudi Arabia (+966)' },
        { countryCode: 'SN', value: '221', label: 'Senegal (+221)' },
        { countryCode: 'CS', value: '381', label: 'Serbia (+381)' },
        { countryCode: 'SC', value: '248', label: 'Seychelles (+248)' },
        { countryCode: 'SL', value: '232', label: 'Sierra Leone (+232)' },
        { countryCode: 'SG', value: '65', label: 'Singapore (+65)' },
        { countryCode: 'SX', value: '1', label: 'Sint Maarten (+1)' },
        { countryCode: 'SK', value: '421', label: 'Slovak Republic (+421)' },
        { countryCode: 'SI', value: '386', label: 'Slovenia (+386)' },
        { countryCode: 'SB', value: '677', label: 'Solomon Islands (+677)' },
        { countryCode: 'SO', value: '252', label: 'Somalia (+252)' },
        { countryCode: 'ZA', value: '27', label: 'South Africa (+27)' },
        { countryCode: 'ES', value: '34', label: 'Spain (+34)' },
        { countryCode: 'LK', value: '94', label: 'Sri Lanka (+94)' },
        { countryCode: 'SH', value: '290', label: 'St. Helena (+290)' },
        { countryCode: 'KN', value: '1869', label: 'St. Kitts (+1869)' },
        { countryCode: 'SC', value: '1758', label: 'St. Lucia (+1758)' },
        { countryCode: 'SD', value: '249', label: 'Sudan (+249)' },
        { countryCode: 'SR', value: '597', label: 'Suriname (+597)' },
        { countryCode: 'SZ', value: '268', label: 'Swaziland (+268)' },
        { countryCode: 'SE', value: '46', label: 'Sweden (+46)' },
        { countryCode: 'CH', value: '41', label: 'Switzerland (+41)' },
        { countryCode: 'SI', value: '963', label: 'Syria (+963)' },
        { countryCode: 'TW', value: '886', label: 'Taiwan (+886)' },
        { countryCode: 'TJ', value: '7', label: 'Tajikstan (+7)' },
        { countryCode: 'TH', value: '66', label: 'Thailand (+66)' },
        { countryCode: 'TG', value: '228', label: 'Togo (+228)' },
        { countryCode: 'TO', value: '676', label: 'Tonga (+676)' },
        { countryCode: 'TT', value: '1868', label: 'Trinidad & Tobago (+1868)' },
        { countryCode: 'TN', value: '216', label: 'Tunisia (+216)' },
        { countryCode: 'TR', value: '90', label: 'Turkey (+90)' },
        { countryCode: 'TM', value: '7', label: 'Turkmenistan (+7)' },
        { countryCode: 'TM', value: '993', label: 'Turkmenistan (+993)' },
        { countryCode: 'TC', value: '1649', label: 'Turks & Caicos Islands (+1649)' },
        { countryCode: 'TV', value: '688', label: 'Tuvalu (+688)' },
        { countryCode: 'UG', value: '256', label: 'Uganda (+256)' },
        { countryCode: 'UA', value: '380', label: 'Ukraine (+380)' },
        { countryCode: 'AE', value: '971', label: 'United Arab Emirates (+971)' },
        { countryCode: 'GB', value: '44', label: 'United Kingdom (+44)' },
        { countryCode: 'US', value: '1', label: 'United States of America (+1)' },
        { countryCode: 'UY', value: '598', label: 'Uruguay (+598)' },
        { countryCode: 'UZ', value: '7', label: 'Uzbekistan (+7)' },
        { countryCode: 'VU', value: '678', label: 'Vanuatu (+678)' },
        { countryCode: 'VA', value: '379', label: 'Vatican City (+379)' },
        { countryCode: 'VE', value: '58', label: 'Venezuela (+58)' },
        { countryCode: 'VN', value: '84', label: 'Vietnam (+84)' },
        { countryCode: 'VG', value: '84', label: 'Virgin Islands - British (+1284)' },
        { countryCode: 'VI', value: '84', label: 'Virgin Islands - US (+1340)' },
        { countryCode: 'WF', value: '681', label: 'Wallis and Futuna (+681)' },
        { countryCode: 'YE', value: '969', label: 'Yemen (North) (+969)' },
        { countryCode: 'YE', value: '967', label: 'Yemen (South) (+967)' },
        { countryCode: 'ZM', value: '260', label: 'Zambia (+260)' },
        { countryCode: 'ZW', value: '263', label: 'Zimbabwe (+263)' },
      ],

      isModalIntro: false,
      intromessage: '',
    }
  },
  created() {
    if (localStorage.getItem("sub") === null) {
      const currentroute = this.$router.currentRoute.path;
      localStorage.setItem('lastpage', currentroute);
    }
    this.checklogin();
    // this.getUser();
  },
  methods: {
    async checklogin() {
      if (localStorage.getItem("sub") !== null) {
        const inme = await this.getme();
        this.loggedin = true;
        localStorage.removeItem('lastpage');

      }
      this.getUser();
    },

    async getUser() {
      this.User = await this.DataGet(`/user/${this.area}/${this.id}`);
      console.log('inuser', this.User);
      this.Avail = await this.DataGet(`/available/${this.User._id}`);
      console.log(this.Avail);
      if(this.loggedin) {
        const getcirc = await this.DataSend('post', '/incircle', { sub: this.Me._id, toid: this.User._id }, 'rows', false);
        if (getcirc !== null) {
          this.incircles = true;
        }
      }
      this.isLoading = false;
    },

    shortlistAdd() {
      let showerr = false;
      let errmessage = '';
      let errbutton = '';
      if (!this.loggedin) {
        showerr = true;
        errmessage = 'You need to be logged in to add someone to your shortlist';
        errbutton = 'Register/Login';
      }
      if (showerr) {
        this.$buefy.dialog.confirm({ 	
          title: '',	
          message: errmessage,	
          confirmText: errbutton, type: 'is-danger',	hasIcon: true,	icon: 'exclamation',	
          onConfirm: () => {
            this.$router.push({ name: 'signup' });
          }
         });
      } else {

      }
    },
    sendMessage() {
      let showerr = false;
      let errmessage = '';
      let errbutton = '';
      let errpath = 'signup';
      if (!this.loggedin) {
        showerr = true;
        errmessage = 'You need to be logged in to send a message';
        errbutton = 'Register/Login';
        errpath = 'signup';
      }

      if (this.loggedin) {
        if (this.Me.planid === '5ea14668045019ad7e6ff86e') {
          showerr = true;
          errmessage = 'Sorry this functionality is not available to free plans';
          errbutton = 'Upgrade Now!';
          errpath = 'account';
        }
      }

      if (showerr) {
        this.$buefy.dialog.confirm({ 	
          title: '',	
          message: errmessage,	
          confirmText: errbutton, type: 'is-danger',	hasIcon: true,	icon: 'exclamation',	
          onConfirm: () => {
            this.$router.push({ name: errpath });
          }
         });
      } else {
        this.isModalIntro = true;
      }
    },

    async sendIntro() {
      if (this.intromessage === '') { return; }
      if (this.intromessage.length < 100) { console.log('less'); return; }
      console.log('passed');
      const sendmess = {
        _id: this.User._id,
        message: this.intromessage,
      }
      const sendm = await this.DataSend('post', '/sendintro', sendmess);
      this.intromessage = '';
      this.isModalIntro = false;
    },

    makeBooking(a, d) {
      console.log('d', d);
      this.ActiveDay = a;
      this.ActiveBooking = d;
      if (localStorage.getItem("sub") === null) {
        this.showBook = true;
        this.loggedin = false;
      } else {
        this.loggedin = true;
        const inuser = this.GetMeLocal();
        this.sub = inuser.sub;
        this.subname = inuser.subname;
        this.$buefy.dialog.confirm({ 	
          title: '',	
          message: `You are about to confirm a booking with ${ this.User.firstname } ${ this.User.lastname }`,	
          confirmText: 'Confirm',	
          type: 'is-info',	
          hasIcon: true,	
          icon: 'exclamation',	
          onConfirm: () => {
            this.makeBookingLoggedIn();
          }
         });
      }
    },

    async makeBookingLoggedIn() {
      const nr = {
          firstname: this.Me.firstname,
          lastname: this.Me.lastname,
          email: this.Me.email,
          mobile: this.Me.mobile,
          confirmed: true,
          sub: this.Me._id,
          handle: this.Me.handle,
          profile: this.Me.oneliner,
        }
        const who = {
          _id: this.User._id,
          firstname: this.User.firstname,
          lastname: this.User.lastname,
          handle: this.User.handle,
          oneliner: this.User.oneliner,
        }
        this.ActiveDay.dateformatted = this.dateFormatted(this.ActiveDay.daytxt);
        const fdate = this.getDateFull(this.ActiveDay.daytxt, 'none', 0, 0);
        this.ActiveDay.year = fdate.year;
        this.ActiveDay.month = fdate.monthsingle;
        this.ActiveDay.day = fdate.daysingle;
        const data = { day: this.ActiveDay, booking: this.ActiveBooking, nr, who, url: window.location.href };
        console.log(data);
        const booking = await this.DataSend('post', '/dobooking', data, '', false);
        console.log(booking);
        if (booking.message === 'Booked') {
          this.showBook = false;
          this.$buefy.dialog.alert({
              title: '',
              message: `${this.ActiveBooking.slottype} confirmed`,
              type: 'is-success',
              hasIcon: true,
              icon: 'thumbs-up',
              iconPack: 'fal',
              ariaRole: 'alertdialog',
              ariaModal: true
          });
          this.Avail = await this.DataGet(`/available/${this.User._id}`);
        }
    },

    async makeBookingDo() {
      console.log('makeBookingDo');
      console.log('this.ActiveBooking', this.ActiveDay, this.ActiveBooking);
      if (this.firstname === '') { this.$buefy.snackbar.open({ message: 'Please enter your first name', type: 'is-warning', position: 'is-bottom' }); return; }
      if (this.lastname === '') { this.$buefy.snackbar.open({ message: 'Please enter your last name', type: 'is-warning', position: 'is-bottom' }); return; }
      if (this.email === '') { this.$buefy.snackbar.open({ message: 'Please enter a valid email', type: 'is-warning', position: 'is-bottom' }); return; }
      if (this.mobile === '') { this.$buefy.snackbar.open({ message: 'Please enter your mobile number', type: 'is-warning', position: 'is-bottom' }); return; }
      
      if (this.showRegister) {

      } else {
        const nr = {
          firstname: this.firstname,
          lastname: this.lastname,
          email: this.email,
          mobile: this.mobile,
          confirmed: true,
          sub: this.sub,
          handle: this.handle,
          profile: this.profile,
        }
        const who = {
          _id: this.User._id,
          firstname: this.User.firstname,
          lastname: this.User.lastname,
          handle: this.User.handle,
          oneliner: this.User.oneliner,
        }
        this.ActiveDay.dateformatted = this.dateFormatted(this.ActiveDay.daytxt);
        const fdate = this.getDateFull(this.ActiveDay.daytxt, 'none', 0, 0);
        this.ActiveDay.year = fdate.year;
        this.ActiveDay.month = fdate.monthsingle;
        this.ActiveDay.day = fdate.daysingle;
        const data = { day: this.ActiveDay, booking: this.ActiveBooking, nr, who, url: window.location.href };
        console.log(data);
        const booking = await this.DataSend('post', '/dobooking', data, '', false);
        console.log(booking);
        if (booking.message === 'Booked') {
          this.showBook = false;
          this.$buefy.dialog.alert({
              title: '',
              message: `${this.ActiveBooking.slottype} confirmed`,
              type: 'is-success',
              hasIcon: true,
              icon: 'thumbs-up',
              iconPack: 'fal',
              ariaRole: 'alertdialog',
              ariaModal: true
          });
          this.Avail = await this.DataGet(`/available/${this.User._id}`);
        }
      }
    },

    async loginpre() {
      if (this.email === "") {
        this.$buefy.snackbar.open({
          message: "Please enter a valid email address",
          position: "is-top"
        });
        return;
      }
      try {
        let response = await this.DataSend('post', `/loginpre`, {
          uname: this.email
        });
        const data = response;
        if (data.status === 400) {
          this.$buefy.snackbar.open({
            message: data.message,
            position: "is-top"
          });
          return;
        }
        if (data.type === "pass") {
          this.showPass = true;
          this.showProceed = false;
        } else {
          this.showTfa = true;
          this.showProceed = false;
        }
      } catch (err) {}
    },

    async loginpass() {
      if (this.email === "") {
        this.$buefy.snackbar.open({
          message: "Please enter a valid email address",
          position: "is-top"
        });
        return;
      }
      if (this.password === "") {
        this.$buefy.snackbar.open({
          message: "Please enter your password",
          position: "is-top"
        });
        return;
      }
      try {
        let response = await this.DataSend('post', `/loginpass`, {
          uname: this.email,
          upass: this.password
        });
        const data = response;
        if (data.status === 400) {
          this.$buefy.snackbar.open({
            message: data.message,
            position: "is-top"
          });
          return;
        }
        if (data.status === 200) {
          this.$buefy.snackbar.open({
            message: data.message,
            position: "is-top"
          });
          localStorage.setItem("token", data.token);
          localStorage.setItem("sub", data.sub);
          localStorage.setItem("firstname", data.firstname);
          localStorage.setItem("lastname", data.lastname);
          localStorage.setItem('subname', `${data.firstname} ${data.lastname}`);
          this.sub = data.sub;
          this.subname = `${data.firstname} ${data.lastname}`;
          this.loggedin = true;
          localStorage.setItem("isadmin", data.admin);
          localStorage.setItem("demo", data.demo);
          const user = {
            sub: data.sub,
            subname: `${data.firstname} ${data.lastname}`,
            email: data.email,
            admin: data.admin
          };
          // this.$store.dispatch("setUser", user);
          const inperms = "" + JSON.stringify(data.permissions);
          localStorage.setItem("permissions", inperms);
          // EventBus.$emit("check_usr", {});
          // this.$router.push({ name: "home" });
        }
      } catch (error) {
        this.$buefy.snackbar.open({ message: error, position: "is-top" });
      }
    },
    gotosignup() {
      	this.$router.push({ name: 'signup' });
    },
  }
}
</script>